import { createSlice } from '@reduxjs/toolkit';
// utils
import { AxiosError } from 'axios';
import api from '../../utils/axios';
import { setSessionStore } from '../../utils/jwt';

// @types
import { dispatch } from '../store';
// ----------------------------------------------------------------------

type ApiState = {
  isLoading: boolean;
  error: Error | string | null;
};

const initialState: ApiState = {
  isLoading: true,
  error: null,
};

const slice = createSlice({
  name: 'apiStore',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

async function getNewAccessToken(refreshToken: string) {
  api.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
  try {
    var response = await api.get('/auth/new-token-management');
    var newAccessToken = response.data.accessToken;
    setSessionStore(newAccessToken, refreshToken);
    return true;
  } catch (e) {
    if (e?.response?.data?.code === 401 || e?.response?.status === 401) {
      console.log('UNAUTHORIZED ERROR: ' + e.response.data.message);
    }
    if (e?.message === 'Token inválido' || e?.response?.data?.message === 'Token inválido') {
      console.log('UNAUTHORIZED ERROR: ' + e.response.data.message);
    }
    if (e?.response?.data?.code === 500 || e?.response?.status === 500) {
      console.log('ERRO DESCONHECIDO');
    }
    // throw e;
    return false;
  }
}

export const get: any = async (url: string, obj?: any, retries=1) => {
    dispatch(slice.actions.startLoading());
    var refreshToken = window.localStorage.getItem('@cnStore-refreshToken');

    try {
      var res = await api.get(url, obj);
      return res;
      
    }catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 401) {
          if (refreshToken && retries > 0) {
            await getNewAccessToken(refreshToken);
            return get(url, obj, retries - 1);
          }
        } else if (e.response?.status === 403) {
          // A principio: deixar cair no reject
          // Mas pode-se querer tratar de alguma forma diferente
        } else {
          // A principio: deixar cair no reject
          // Mas pode-se querer tratar de alguma forma diferente
        }
      } else { }
      // Outros erros devem ser tratados abaixo
      throw e;
    }
}

export const post: any = async (url: string, body: any, retries = 1) => {
  dispatch(slice.actions.startLoading());
  var refreshToken = window.localStorage.getItem('@cnStore-refreshToken');

  try {
    var res = await api.post(url, body);
    return res;
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 401) {
        if (refreshToken && retries > 0) {
          await getNewAccessToken(refreshToken);
          return post(url, body, retries - 1);
        }
      } else if (e.response?.status === 403) {
        // A principio: deixar cair no reject
        // Mas pode-se querer tratar de alguma forma diferente
      } else {
        // A principio: deixar cair no reject
        // Mas pode-se querer tratar de alguma forma diferente
      }
    } else { }
    // Outros erros devem ser tratados abaixo
    throw e;
  }
}


export const put: any = async (url: string, body: any, retries=1) => {
  dispatch(slice.actions.startLoading());
  var refreshToken = window.localStorage.getItem('@cnStore-refreshToken');

  try {
    var res = await api.put(url, body);
    return res;
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 401) {
        if (refreshToken && retries > 0) {
          await getNewAccessToken(refreshToken);
          return put(url, body, retries - 1);
        }
      } else if (e.response?.status === 403) {
        // A principio: deixar cair no reject
        // Mas pode-se querer tratar de alguma forma diferente
      } else {
        // A principio: deixar cair no reject
        // Mas pode-se querer tratar de alguma forma diferente
      }
    } else { }
    // Outros erros devem ser tratados abaixo
    throw e;
  }
}
