// components
// import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const navConfig = [
  {
    items: [
      { 
        title: 'Resgatar benefício', 
        path: '/store/benefits-list', 
      },
    ]
  },
];

export default navConfig;
