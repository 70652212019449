import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import promoterReducer from './slices/promoter';
import clientReducer from './slices/client';
import bonusReducer from './slices/bonus';
import storeReducer from './slices/store';
import benefitsReducer from './slices/benefits';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  promoter: promoterReducer,
  client: clientReducer,
  bonus: bonusReducer,
  store: storeReducer,
  benefits: benefitsReducer
});

export { rootPersistConfig, rootReducer };
