import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// import axios from '../../utils/axios';
import { get, post } from './api';
// import { AxiosError } from 'axios';

type ClientState = {
  isLoading: boolean,
  error: any,
  data: any,
  cellphoneFormData: any,
  isError: boolean,
  description: string,
  title: string,
  client: any
}

const initialState: ClientState = {
  isLoading: false,
  error: null,
  data: null,
  cellphoneFormData: null,
  isError: false,
  description: '',
  title: '',
  client: null
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setModalInfos(state, action) {
      state.description = action.payload.description;
      state.title = action.payload.title;
      state.isError = action.payload.isError;
    },

    setPhoneFormData(state, action) {
      state.cellphoneFormData = action.payload
    },

    getClientSuccess(state, action){
      state.isLoading = false;
      state.client = action.payload;
    }
  },
});

export default slice.reducer;

export const { setPhoneFormData, setModalInfos } = slice.actions;

export function getByMembercard(membercard: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(`/auth/user-by-member-code?memberCode=${membercard}`);
      if(response?.data?.phone) {
        if(response?.data?.phone.length === 11) {
          response.data.phone = response?.data?.phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else {
          response.data.phone = response?.data?.phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        }
      }
      dispatch(slice.actions.getClientSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return error;
    }
  };
}

export function saveLog(memberCard: string) {
  return async () => {
    try {
      await post(`/analytics/events/many`,
        [{
          eventType: 'action',
          screenName: 'carteirinha',
          actionType: 'segue',
          eventDate: new Date(),
          parameter: memberCard,
          otherData: {
            description: 'Teve o QR Code lido',
          }
        }]
      );
      return true;
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return error;
    }
  };
}